import { Button, Icon, Link, Typography } from "@mui/material";
import React, { useState } from "react";

import AuthService from "../services/AuthService";
import mobileStyles from '../styles/Navigation.mobile.module.css';
import styles from '../styles/Navigation.module.css';
import { useNavigate } from "react-router-dom";

interface NavigationProps {
    isMobile: boolean
    config: Config
}

export const Navigation: React.FC<NavigationProps> = (props) => {
    const navigate = useNavigate();
    //Mobile-attribute
    const [unfolded, setUnfolded] = useState<boolean>(false);

    if (!props.isMobile) {
        return (
            <div>
                <img src={`${process.env.https://crm.buergerenergie.bayern}/clientconfig/logo`} className={styles.navigationLogo} alt="Logo der Genossenschaft" />
                <Typography className={styles.navigationTitle} variant="h6">{props.config.cooperativeName}</Typography>
                <Button className={styles.switchMemberBtn} onClick={() => navigate('/switchmember')}>Weitere Mitgliedschaften verwalten</Button>
                <div className={styles.navigationDiv}>
                    <Link className={styles.navigationLink} onClick={() => navigate('/')}>Dashboard</Link>
                    <Link className={styles.navigationLink} onClick={() => navigate('/personaldata')}>Persönliche Daten</Link>
                    <Link className={styles.navigationLink} onClick={() => navigate('/memberaccount')}>Mitgliedskonto</Link>
                    <Link className={styles.navigationLink} onClick={() => navigate('/shares')}>Genossenschaftsanteile</Link>
                    <Link className={styles.navigationLink} onClick={() => navigate('/projectloans')}>Projektdarlehen</Link>
                    <Link className={styles.navigationLink} onClick={() => navigate('/attachments')}>Dokumente</Link>
                    <Link className={styles.navigationLink} onClick={() => { AuthService.doLogout() }} >Abmelden ({AuthService.getUsername()})</Link>
                </div>
            </div>
        )
    }

    return (<>
        <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <Typography className={styles.navigationTitle} variant="h6">{props.config.cooperativeName}</Typography>
        <div className={mobileStyles.navigationDiv} onClick={() => setUnfolded(!unfolded)}>
            <Icon sx={{ fontSize: 30 }}>reorder</Icon>
        </div>
        <div style={{ display: unfolded ? "block" : "none" }} className={mobileStyles.dropdown}>
            <Link className={mobileStyles.navigationLink} onClick={() => { navigate('/'); setUnfolded(false) }}>Dashboard</Link>
            <Link className={mobileStyles.navigationLink} onClick={() => { navigate('/personaldata'); setUnfolded(false) }}>Persönliche Daten</Link>
            <Link className={mobileStyles.navigationLink} onClick={() => { navigate('/memberaccount'); setUnfolded(false) }}>Mitgliedskonto</Link>
            <Link className={mobileStyles.navigationLink} onClick={() => { navigate('/shares'); setUnfolded(false) }}>Genossenschaftsanteile</Link>
            <Link className={mobileStyles.navigationLink} onClick={() => { navigate('/projectloans'); setUnfolded(false) }}>Projektdarlehen</Link>
            <Link className={mobileStyles.navigationLink} onClick={() => { navigate('/attachments'); setUnfolded(false) }}>Dokumente</Link>
            <Link className={mobileStyles.navigationLink} onClick={() => { navigate('/switchmember'); setUnfolded(false) }}>Weitere Mitgliedschaften verwalten</Link>
            <Link className={mobileStyles.navigationLink} onClick={() => { AuthService.doLogout(); setUnfolded(false) }} >Abmelden ({AuthService.getUsername()})</Link>
        </div>
        <div style={{ height: 15 }}></div>

    </>
    )
}
