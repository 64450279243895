import { Loan, Member, ProjectDto } from '../types';

import AuthService from "./AuthService";
import axios from 'axios';

class LoanService {
    static async getById(loanId: string): Promise<Loan>{
        await AuthService.updateToken(null);
        return axios.get(`${process.env.https://crm.buergerenergie.bayern}/loan/${loanId}`, { headers: { Authorization: `Bearer ${AuthService.getToken()}` } })
        .then((response) => {
            if(response.status === 200) return response.data
        });
    }

    static async getProjectForLoan(loanId: string): Promise<ProjectDto>{
        await AuthService.updateToken(null);
        return axios.get(`${process.env.https://crm.buergerenergie.bayern}/loan/${loanId}/project`, { headers: { Authorization: `Bearer ${AuthService.getToken()}` } })
        .then((response) => {
            if(response.status === 200) return response.data
        });
    }

    static async getMemberForLoan(loanId: string): Promise<Member>{
        await AuthService.updateToken(null);
        return axios.get(`${process.env.https://crm.buergerenergie.bayern}/loan/${loanId}/member`, { headers: { Authorization: `Bearer ${AuthService.getToken()}` } })
        .then((response) => {
            if(response.status === 200) return response.data
        });
    }
}

export default LoanService;

