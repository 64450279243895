import AuthService from "./AuthService";
import EmptyTypesService from "./EmptyTypesService";
import { Member } from "../types";
import axios from "axios";

let memberData: Member[] = [];

let currentMember: Member = EmptyTypesService.emptyMember();

const setMemberData = (data: Member[]) => {
    if (currentMember.id === "") {
        setCurrentMember(data[0])
    }
    memberData = data;
}

const getMemberData = () => {
    return memberData;
}

const setCurrentMember = (data: Member) => {
    currentMember = data;
}

const getCurrentMember = (): Member  => {
    return currentMember;
}

const getUrlAddition = (multiple:boolean = false) => {
    if (currentMember.id === "") {
        return "";
    } else {
        if (multiple) {
            return `&memberId=${currentMember.id}`
        } else {
            return `?memberId=${currentMember.id}`
        }
    }
}

async function getById(memberId: string): Promise<Member>{
    await AuthService.updateToken(null);
    return axios.get(`${process.env.https://crm.buergerenergie.bayern}/member/${memberId}`, { headers: { Authorization: `Bearer ${AuthService.getToken()}` } })
    .then((response) => {
        if(response.status === 200) return response.data
    });
}

const MemberService = {
    setMemberData,
    getMemberData,
    setCurrentMember,
    getCurrentMember,
    getUrlAddition
};

export default MemberService;